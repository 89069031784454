import { useEffect } from "react";

function ProfileKL() {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/custom-scripts.js";
        script.async = true;
        script.onload = () => {};

        document.body.appendChild(script);
    });

    return (
        <>
            {/* Start Loader */}
            <div className="section-loader">
                <div className="loader">
                    <div></div>
                    <div></div> 
                </div>
            </div>
            {/* End Loader */}
            
            {/*
            ===================
            NAVIGATION
            ===================
            */}
            <header className="black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav" id="mh-header">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg mh-nav nav-btn">
                            <a className="navbar-brand" href="#">
                                <img src="assets/images/logo.png" alt="Logo" className="img-fluid" />
                                {/* <h2>Maha</h2> */}
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon icon"></span>
                            </button>
                        
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-0 ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="#mh-home">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-about">About</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="#mh-skills">Skills</a>
                                    </li>                                
                                    <li className="nav-item">
                                    <a className="nav-link" href="#mh-experience">Experiences</a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="#mh-contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

            {/*
            ===================
            Home
            ===================
            */}
            <section className="mh-home image-bg home-2-img" id="mh-home">
                <div className="img-foverlay img-color-overlay">
                    <div className="container">
                        <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
                            <div className="col-sm-6">
                                <div className="mh-header-info">
                                    <div className="mh-promo wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
                                        <span>Hello I'm</span>
                                    </div>
                                    
                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Kevin Lee</h2>
                                    <h4 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">Full-Stack Developer |<br/> Specializing in DevOps & Backend</h4>
                                    
                                    <ul>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s"><i className="fa fa-envelope"></i><a href="mailto:kevinleeonx@gmail.com">kevinleeonx@gmail.com</a></li>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s"><i className="fa fa-phone"></i><a href="callto:+16194942129">619 494 2129</a></li>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s"><i className="fa fa-map-marker"></i><address>Chula Vista, CA 91913</address></li>
                                    </ul>
                                    {/*
                                    <ul className="social-icon wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                        <li><a href=""><i className="fa fa-facebook"></i></a></li>
                                        <li><a href=""><i className="fa fa-twitter"></i></a></li>
                                        <li><a href=""><i className="fa fa-github"></i></a></li>
                                        <li><a href=""><i className="fa fa-dribbble"></i></a></li>
                                    </ul>
                                    */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="hero-img wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                    <div className="img-border">
                                        <img src="assets/images/profile1.png" alt=""  className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            ABOUT
            ===================
            */}
            <section className="mh-about" id="mh-about">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                <img src="assets/images/ab-img.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-inner">
                            <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">About Me</h2>
                            <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            Full-stack web developer with over 10 years of experience. <br/>
                            Proven track record of working with startup companies to accelerate growth and deliver quality software solutions. <br/>
                            Skilled in agile development, team leadership, and cost-effective project management. <br/>
                            Available for freelance or full-time positions requiring agile collaboration and timely delivery. <br/>
                            My practice area :
                            </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mh-about-tag mh-about-tag-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <ul>
                                    <li><span>JavaScript</span></li>
                                    <li><span>TypeScript</span></li>
                                    <li><span>PHP</span></li>
                                    <li><span>Python</span></li>
                                    <li><span>Java</span></li>
                                    <li><span>Golang</span></li>
                                    <li><span>Ruby</span></li>
                                    <li><span>C#</span></li>
                                    <li><span>Node.js</span></li>
                                    <li><span>React.js</span></li>
                                    <li><span>Next.js</span></li>
                                    <li><span>Laravel</span></li>
                                    <li><span>Spring</span></li>
                                    <li><span>Angular</span></li>
                                    <li><span>Ruby on Rails</span></li>
                                    <li><span>ASP.NET Core</span></li>
                                    <li><span>GCP</span></li>
                                    <li><span>AWS</span></li>
                                    <li><span>Azure</span></li>
                                    <li><span>Alibaba Cloud</span></li>
                                    <li><span>M2X IoT</span></li>
                                    <li><span>MySQL</span></li>
                                    <li><span>MongoDB</span></li>
                                    <li><span>PostgreSQL</span></li>
                                    <li><span>MS-SQL</span></li>
                                    <li><span>NoSQL</span></li>
                                    <li><span>DevOps</span></li>
                                    <li><span>CI/CD</span></li>
                                    <li><span>IaC</span></li>
                                    <li><span>Terraform</span></li>
                                    <li><span>Kubernetes</span></li>
                                    <li><span>Docker</span></li>
                                    <li><span>CircleCI</span></li>
                                    <li><span>Jenkins</span></li>
                                </ul>
                                <a href="assets/docs/Kevin Lee.pdf" target="_blank" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Downlaod CV <i className="fa fa-download"></i></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            SERVICE
            ===================
            */}
            <section className="mh-service">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 text-center section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            <h2>What I do</h2>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <i className="fa fa-bullseye purple-color"></i>
                                <h3>Frontend Development</h3>
                                <p>
                                    Custom UI Design <br/>
                                    Responsive Design <br/>
                                    Modern Frameworks <br/>
                                    Cross-Browser Compatibility <br/>
                                    UI/UX Enhancements <br/>
                                    Performance Optimization <br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                <i className="fa fa-code iron-color"></i>
                                <h3>Backend Development</h3>
                                <p>
                                    Custom API Development <br/>
                                    Database Design & Management <br/>
                                    Server-Side Logic <br/>
                                    Authentication & Security <br/>
                                    Cloud Integration <br/>
                                    Performance Optimization <br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <i className="fa fa-object-ungroup sky-color"></i>
                                <h3>DevOps Engineering</h3>
                                <p>
                                    Continuous Integration & Deployment (CI/CD) <br/>
                                    Infrastructure as Code (IaC) <br/>
                                    Cloud Management <br/>
                                    Monitoring & Logging <br/>
                                    Containerization & Orchestration <br/>
                                    Performance & Cost Optimization <br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            FEATURE PROJECTS
            ===================
            */}
            <section className="mh-featured-project image-bg featured-img-one">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="section-title col-sm-12">
                                <h3>Featured Projects</h3>
                            </div>
                            <div className="col-sm-12">
                                <div className="mh-single-project-slide-by-side row">
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2-1.jpg" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    {/* <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Frontend, Backend, DevOps</h4> */}
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">HRN</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">SaaS Platform for Horse Racing Fans</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                                        HorseRacesNow is a comprehensive platform for horse racing enthusiasts, offering real-time information and engagement features. The project encompassed developing mobile applications for iOS and Android, a responsive web application, an admin dashboard for streamlined management, and an intuitive landing page for user acquisition.
                                                    </p>
                                                    <a href="https://app.horseracesnow.com/home" target="_blank" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s" rel="noreferrer">Visit</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            Skills
                                                            <cite>TypeScript, React.js, Node.js, Firebase(Authentication, Firestore, Functions, Messaging, Storage), GCP(Cloud Run, Cloud Task, Pub/Sub), MongoDB, ElasticSearch, Text-to-Speech, Stripe Integration</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>TypeScript, React.js, Node.js, Firebase(Authentication, Firestore, Functions, Messaging, Storage), GCP(Cloud Run, Cloud Task, Pub/Sub), MongoDB, ElasticSearch, Text-to-Speech, Stripe Integration</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>TypeScript, React.js, Node.js, Firebase(Authentication, Firestore, Functions, Messaging, Storage), GCP(Cloud Run, Cloud Task, Pub/Sub), MongoDB, ElasticSearch, Text-to-Speech, Stripe Integration</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2-2.jpg" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    {/* <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Web Design</h4> */}
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">SupplyBridge</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">SaaS Platform for Supply Chain Management</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                                        SupplyBridge is an innovative platform that streamlines the supply chain management process, connecting businesses with suppliers for efficient collaboration. As a Full-Stack Developer on the project, I contributed to building the MVP and ensuring its robust architecture, scalability, and performance.
                                                    </p>
                                                    <a href="https://supplybridge.com/" target="_blank" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s" rel="noreferrer">Visit</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, Next.js, Node.js, PostgreSQL, Azure, DevOps, CI/CD, Docker</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, Next.js, Node.js, PostgreSQL, Azure, DevOps, CI/CD, Docker</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, Next.js, Node.js, PostgreSQL, Azure, DevOps, CI/CD, Docker</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2-3.jpg" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    {/* <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Web Design</h4> */}
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">Talkia</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">Text-to-Speech Software</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                                    Talkia is a cutting-edge text-to-speech platform that allows users to create professional voiceovers for videos, presentations, and other multimedia content. The platform provides a wide range of natural-sounding voices, multiple languages, and customizable options to cater to diverse user needs.
                                                    </p>
                                                    <a href="https://www.talkia.com/" target="_blank" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s" rel="noreferrer">Visit</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, React.js, Electron.js, PHP, Laravel, MySQL, FFmpeg, AWS(ECS, SES, S3, Polly), Google Cloud Text-to-Speech, Docker, CircleCI</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, React.js, Electron.js, PHP, Laravel, MySQL, FFmpeg, AWS(ECS, SES, S3, Polly), Google Cloud Text-to-Speech, Docker, CircleCI</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, React.js, Electron.js, PHP, Laravel, MySQL, FFmpeg, AWS(ECS, SES, S3, Polly), Google Cloud Text-to-Speech, Docker, CircleCI</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Project Items */}
                                    <div className="col-sm-12 mh-featured-item">
                                        <div className="row">
                                            <div className="col-sm-7">
                                                <div className="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                                    <img src="assets/images/p-2-4.jpg" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="mh-featured-project-content">
                                                    {/* <h4 className="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">Web Design</h4> */}
                                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">Doodly</h2>
                                                    <span className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">Whiteboard Animation Software</span>
                                                    <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                                    Doodly is an intuitive whiteboard animation software that enables users to create professional, engaging, and customizable doodle videos without any technical expertise. The platform is widely used for marketing, training, education, and content creation.
                                                    </p>
                                                    <a href="https://www.doodly.com/" target="_blank" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s" rel="noreferrer">Visit</a>
                                                    <div className="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, React.js, Electron.js, PHP, Laravel, MySQL, FFmpeg, HTML5 Canvas</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, React.js, Electron.js, PHP, Laravel, MySQL, FFmpeg, HTML5 Canvas</cite>
                                                        </blockquote>
                                                        <blockquote>
                                                            Skills
                                                            <cite>JavaScript, React.js, Electron.js, PHP, Laravel, MySQL, FFmpeg, HTML5 Canvas</cite>
                                                        </blockquote>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> {/* End: .row */}
                    </div>
                </div>
            </section>  
            
            {/*
            ===================
            SKILLS
            ===================
            */}
            <section className="mh-skills" id="mh-skills">
                <div className="home-v-img">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="section-title text-center col-sm-12">
                                <h2>Technical Skills</h2>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-skills-inner">
                                    <div className="mh-professional-skill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                        {/*<h3>Technical Skills</h3>*/}
                                        <div className="each-skills">
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">JavaScript</div>
                                                        <div className="percentagem-num">10+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "100%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">PHP</div>
                                                        <div className="percentagem-num">10+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "100%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">TypeScript</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "55%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Java</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">C#</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Python</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Golang</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Ruby</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">React.js</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Node.js</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "55%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Laravel</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "55%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Spring</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Angular</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">ASP.Net</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Ruby on Rails</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-skills-inner">
                                    <div className="mh-professional-skill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                        {/*<h3>Technical Skills</h3>*/}
                                        <div className="each-skills">
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">MySQL</div>
                                                        <div className="percentagem-num">10+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "100%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">PostgreSQL</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "55%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">MongoDB</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "55%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">GCP</div>
                                                        <div className="percentagem-num">5+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "55%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">AWS</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Azure</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Docker</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "35%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">CI/CD</div>
                                                        <div className="percentagem-num">1+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "15%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">IaC</div>
                                                        <div className="percentagem-num">1+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "15%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Terraform</div>
                                                        <div className="percentagem-num">1+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "15%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Kubernetes</div>
                                                        <div className="percentagem-num">1+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "15%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">CircleCI</div>
                                                        <div className="percentagem-num">1+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "15%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="candidatos">
                                                <div className="parcial">
                                                    <div className="info">
                                                        <div className="nome">Jenkins</div>
                                                        <div className="percentagem-num">3+ Years</div>
                                                    </div>
                                                    <div className="progressBar">
                                                        <div className="percentagem" style={{width: "15%"}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            EXPERIENCES
            ===================
            */}
            <section className="mh-experince image-bg featured-img-one" id="mh-experience">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 col-md-12">
                                <div className="mh-work">
                                    <h3>Work Experience</h3>
                                    <div className="mh-experience-deatils">
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                            <h4>Full-Stack Developer at <a href="https://zoartechnology.com/" target="_blank" rel="noreferrer">Zoar Technology LLC</a></h4>
                                            <div className="mh-eduyear">2019-2024</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Led the migration of a legacy system to Firebase and GCP</li>
                                                <li><i className="fa fa-circle"></i>Reduced infrastructure costs by 25% through effective use of cloud resources, while ensuring the platform’s scalability to handle a growing user base</li>
                                                <li><i className="fa fa-circle"></i>Improved the backend system to handle a large volume of data, including race entries, push notifications, statistics, and user preferences</li>
                                                <li><i className="fa fa-circle"></i>Set up system monitoring and logging to ensure real-time performance tracking and issue resolution</li>
                                                <li><i className="fa fa-circle"></i>Integrated third-party services for live races and push notifications, enhancing user experience</li>
                                                <li><i className="fa fa-circle"></i>Collaborated with the mobile development team to optimize app performance for iOS and Android, ensuring fast load times</li>
                                                <li><i className="fa fa-circle"></i>Developed a custom dashboard using React.js to manage users, races, notifications, and data streams, providing admins with real-time control over the platform</li>
                                            </ul>
                                        </div>                                
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                            <h4>DevOps & Backend Developer at <a href="https://supplybridge.com/" target="_blank" rel="noreferrer">Supply Bridge</a></h4>
                                            <div className="mh-eduyear">2023-2023</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Architected a scalable SaaS backend using Node.js</li>
                                                <li><i className="fa fa-circle"></i>Built the cloud infrastructure using Microsoft Azure and Docker containers for scalable hosting</li>
                                                <li><i className="fa fa-circle"></i>Implemented the deployment pipeline using Azure DevOps, automating testing, integration, and deployment to ensure smooth delivery and system reliability</li>
                                                <li><i className="fa fa-circle"></i>Integrated third-party APIs for real-time data exchange, enabling efficient inventory tracking, order fulfillment, and analytics</li>
                                                <li><i className="fa fa-circle"></i>Optimized database performance and ensured data consistency using PostgreSQL for handling large volumes of inventory and order data</li>
                                            </ul>
                                        </div>                                
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                            <h4>Full-Stack Developer at <a href="https://clickfunnels.com/" target="_blank" rel="noreferrer">ClickFunnels</a></h4>
                                            <div className="mh-eduyear">2021-2023</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Led the development of the application, admin dashboard, and backend using Electron.js and Laravel framework</li>
                                                <li><i className="fa fa-circle"></i>Integrated advanced text-to-speech engines using Google Text-to-Speech and AWS Polly to deliver high-quality, natural voiceovers in multiple languages and accents</li>
                                                <li><i className="fa fa-circle"></i>Collaborated with the UI/UX team to develop an intuitive interface that allows users to easily input text, select voice options, and generate audio files</li>
                                                <li><i className="fa fa-circle"></i>Built features that enable users to customize speech parameters like pitch, speed, and tone, and export the audio in various formats (e.g., MP3, WAV)</li>
                                                <li><i className="fa fa-circle"></i>Developed the user authentication system and implemented subscription plans, allowing users to manage their accounts and access premium voiceover services</li>
                                            </ul>
                                        </div>
                                        {/* Education Institutes*/}
                                        <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                            <h4>Full-Stack Developer at <a href="https://bryxen.com/" target="_blank" rel="noreferrer">Bryxen, Inc</a></h4>
                                            <div className="mh-eduyear">2018-2021</div>
                                            <span>Responsibility :</span>
                                            <ul className="work-responsibility">
                                                <li><i className="fa fa-circle"></i>Developed and maintained the application, admin dashboard, and backend using Electron.js and Laravel framework</li>
                                                <li><i className="fa fa-circle"></i>Contributed to a substantial increase in user adoption, helping Doodly become one of the most widely used whiteboard animation tools</li>
                                                <li><i className="fa fa-circle"></i>Reduced video rendering times, improving overall platform performance and user satisfaction</li>
                                                <li><i className="fa fa-circle"></i>Expanded the animation capabilities by adding new features such as zooming and panning</li>
                                                <li><i className="fa fa-circle"></i>Optimized the scalability to handle high user traffic, maintaining stable performance during peak usage times</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="row section-separator">
                            <div className="col-sm-12 col-md-12">
                                <div className="mh-education">
                                    <h3 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Education</h3>
                                    <div className="mh-education-deatils">
                                        {/* Education Institutes*/}
                                        <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                            <h4>Bachelor of <a href="javascript:void(0)">Computer Software Engineering</a></h4>
                                            <div className="mh-eduyear">2005-2008</div>
                                            <p>Beijing Institute of Technology</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            Testimonial
            ===================
            */}
            <section className="mh-testimonial" id="mh-testimonial">
                <div className="home-v-img">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                <h3>Client Reviews</h3>
                            </div>
                            <div className="col-sm-12 wow fadeInUp" id="mh-client-review" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>                    
                                
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>                    
                                
                                <div className="each-client-item">
                                    <div className="mh-client-item dark-bg black-shadow-1">
                                        <img src="assets/images/c-1.png" alt="" className="img-fluid" />
                                        <p>Absolute wonderful ! I am completely
                                        blown away.The very best.I was amazed
                                        at the quality</p>
                                        <h4>John Mike</h4>
                                        <span>CEO, Author.Inc</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/*
            ===================
            FOOTER 3
            ===================
            */}
            <footer className="mh-footer mh-footer-3" id="mh-contact">
                <div className="container-fluid">
                    <div className="row section-separator">
                        <div className="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            <h3>Contact Me</h3>
                        </div>
                        <div className="map-image image-bg col-sm-12">
                            <div className="container mt-30">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mh-footer-address">
                                        <div className="col-sm-12 xs-no-padding">
                                            <div className="mh-address-footer-item media dark-bg shadow-1 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                                <div className="each-icon">
                                                    <i className="fa fa-envelope-o"></i>
                                                </div>
                                                <div className="each-info media-body">
                                                    <h4>Email</h4>
                                                    <a href="mailto:kevinleeonx@gmail.com">kevinleeonx@gmail.com</a><br/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 xs-no-padding">
                                            <div className="mh-address-footer-item media dark-bg shadow-1 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                                <div className="each-icon">
                                                    <i className="fa fa-phone"></i>
                                                </div>
                                                <div className="each-info media-body">
                                                    <h4>Phone</h4>
                                                    <a href="callto:+16194942129">619 494 2129</a><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                        <form id="contactForm" className="single-form quate-form wow fadeInUp" data-toggle="validator">
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input name="name" className="contact-name form-control" id="name" type="text" placeholder="First Name" required />
                                                </div>
                    
                                                <div className="col-sm-12">
                                                    <input name="name" className="contact-email form-control" id="L_name" type="text" placeholder="Last Name" required />
                                                </div>
                    
                                                <div className="col-sm-12">
                                                    <input name="name" className="contact-subject form-control" id="email" type="email" placeholder="Your Email" required />
                                                </div>
                    
                                                <div className="col-sm-12">
                                                    <textarea className="contact-message" id="message" rows="6" placeholder="Your Message" required></textarea>
                                                </div>
                                                
                                                {/* Subject Button */}
                                                <div className="btn-form col-sm-12">
                                                    <button type="submit" className="btn btn-fill btn-block" id="form-submit">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-sm-12 mh-copyright wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="text-center text-xs-center">
                                                    <p>All right reserved @ 2024</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default ProfileKL;
