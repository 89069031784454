import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './views/home';
import ProfileKL from './views/profile_kl';
import ProfileChris from './views/profile_chris';

function App() {
  return (
    <>
         <Routes>
            <Route path="/kevin-lee" element={<ProfileKL />} />
            <Route path="/chris-vojick" element={<ProfileChris />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Navigate to="/kevin-lee" />} />
         </Routes>
      </>

  );
}

export default App;
